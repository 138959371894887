import Modal from "@material-ui/core/Modal";
import UpdateDealForm from "../../components/UpdateDealForm/UpdateDealForm";

function AddDealModal(props) {
  return (
    <>
      <Modal
        className={"addDealModal"}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock={false}
        disableEscapeKeyDown={false}
        style={{
          borderRadius: "10px",
          width: "90%",
          height: "95%",
          margin: "auto",
          maxWidth: "1080px",
          marginTop: "16px",
          padding: 0,
          color: "#000",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <UpdateDealForm initialValues={props.initialValues} closeAfterSubmit={props.closeAfterSubmit}></UpdateDealForm>
      </Modal>
    </>
  );
}

export default AddDealModal;

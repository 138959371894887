import { useEffect, useState } from "react";
import { useActivityHubFilter } from "../../contexts/ActivityHubContexts";
import useDealConfirmationsCount from "../../typedHooks/queries/queryDealConfirmationsCount";
import CommentsCount from "../CommentsCount/CommentsCount";
import ChecklistItems from "./elements/ChecklistItems";
import { addDays } from "date-fns";
import useDealConditionsCount from "../../typedHooks/queries/queryDealConditionsCount";

const ActivityHubChecklist = () => {
  const { selectedUserViewInArray, checklistView, setChecklistView } = useActivityHubFilter();

  const { dataFromQuery: openDealConfirmationsCount, refetch: refetchOpen } = useDealConfirmationsCount({
    userIds: selectedUserViewInArray,
    isConfirmed: false,
  });
  const { dataFromQuery: confirmedDealConfirmationsCount, refetch: refetchConfirmed } = useDealConfirmationsCount({
    userIds: selectedUserViewInArray,
    isConfirmed: true,
  });
  const [oneWeekFromNow, setOneWeekFromNow] = useState(addDays(new Date(), 7));
  const { dataFromQuery: openDealConditionsCount, refetch: refetchOpenConditions } = useDealConditionsCount({
    isConfirmed: false,
    userIds: selectedUserViewInArray,
    toBeResolvedBefore: oneWeekFromNow,
  });
  const { dataFromQuery: closedDealConditionsCount, refetch: refetchClosedConditions } = useDealConditionsCount({
    isConfirmed: true,
    userIds: selectedUserViewInArray,
  });

  const openChecklistTasksCount =
    openDealConfirmationsCount && openDealConditionsCount ? openDealConfirmationsCount.dealconfirmationscount + openDealConditionsCount.dealconditionscount : 0;

  const closedChecklistTasksCount =
    confirmedDealConfirmationsCount && closedDealConditionsCount
      ? confirmedDealConfirmationsCount.dealconfirmationscount + closedDealConditionsCount.dealconditionscount
      : 0;

  useEffect(() => {
    refetchOpen();
    refetchConfirmed();
    refetchOpenConditions();
    refetchClosedConditions();
  }, [selectedUserViewInArray, refetchOpen, refetchConfirmed, refetchOpenConditions, refetchClosedConditions]);

  return (
    <div className="rounded-2xl bg-white flex flex-wrap w-full xl:w-[calc(33%-16px)] relative min-h-[240px]">
      <aside className="-rotate-90 absolute top-[148px] -left-[28px]">
        <h1 className="bg-[#86CEF3] w-[132px] h-[28px] absolute top-0 whitespace-nowrap rounded-tl-[6px] rounded-tr-[6px] px-[4px] py-[8px] pt-[10px] text-[14px] text-center leading-none text-white">
          Checklist
        </h1>
      </aside>
      <div className="p-4 w-full h-fit relative flex flex-wrap gap-x-[10px]">
        <div className="flex items-end flex-wrap min-h-[44px]">
          <div
            onClick={() => setChecklistView("toDo")}
            className={`border-b-2  flex items-center gap-2 w-fit ml-4 px-1  ${
              checklistView === "toDo" ? "border-primary-blue" : " border-white cursor-pointer"
            }`}
          >
            <h2 className="text-xl">To do</h2>
            <CommentsCount title={"To do"} count={openChecklistTasksCount}></CommentsCount>
          </div>
          <div
            onClick={() => setChecklistView("Done")}
            className={`border-b-2  flex items-center gap-2 w-fit ml-4 px-1  ${
              checklistView === "Done" ? "border-primary-blue" : " border-white cursor-pointer"
            }`}
          >
            <h2 className="text-xl">Done</h2>
            <CommentsCount title={"Done"} count={closedChecklistTasksCount}></CommentsCount>
          </div>
        </div>
        <div className="w-full ml-auto p-4 h-[65vh] no-scrollbar overflow-y-auto">
          {checklistView === "toDo" &&
            (openDealConfirmationsCount && openDealConfirmationsCount.dealconfirmationscount > 0 ? (
              <ChecklistItems column={"toDo"} />
            ) : (
              <div className="bg-gray-100 px-4 py-8 rounded-xl text-center w-full mt-4">
                <span className="text-lg text-center text-gray-400">No pending items</span>
              </div>
            ))}

          {checklistView === "Done" &&
            (confirmedDealConfirmationsCount && confirmedDealConfirmationsCount.dealconfirmationscount > 0 ? (
              <ChecklistItems column={"Done"} />
            ) : (
              <div className="bg-gray-100 px-4 py-8 rounded-xl text-center w-full mt-4">
                <span className="text-lg text-center text-gray-400">No items</span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ActivityHubChecklist;

import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const CREATE_CLIENT = gql`
  mutation Client($input: ClientInput!) {
    client(input: $input) {
      ... on Client {
        id
        companyName
      }
      ... on Error {
        errorCode
        message
      }
      __typename
    }
  }
`;

const useCreateClient = () => {
  const [createClient] = useMutation(CREATE_CLIENT, { refetchQueries: ["clientFormOptions", "getDealOptions"] });

  return createClient;
};

export default useCreateClient;

import { useMutation } from "@apollo/client";
import UPDATE_DEAL from "./mutation_deal";

function useUpdateDeal() {
  const [updateDeal, { data, loading, error }] = useMutation(UPDATE_DEAL, {
    variables: {
      input: {},
    },
    refetchQueries: ["Deal", "dealconfirmationscount", "dealconditionscount", "DealsList"],
  });

  return [updateDeal, data, loading, error];
}

export default useUpdateDeal;

import Modal from "@material-ui/core/Modal";

function AddBackdropModal({ open, handleclose, children, height }) {
  return (
    <>
      <Modal
        className={"addBackdropModal"}
        open={open}
        onClose={handleclose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock={false}
        disableEscapeKeyDown={false}
        style={{
          borderRadius: "10px",
          width: "90%",
          height: height ?? "95%",
          margin: "auto",
          maxWidth: "1080px",
          marginTop: "auto",
          padding: 0,
          color: "#000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </Modal>
    </>
  );
}

export default AddBackdropModal;

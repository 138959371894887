import FormGenerator from "../FormGenerator/FormGenerator";
import { CircularProgress } from "@material-ui/core";
import { FormEvent, useState } from "react";
import { FormField } from "../../interfaces/FormGeneratorProps";
import CreateOrUpdateClientFormProps from "../../interfaces/UpdateClientFormProps";
import useClientFormOptions from "../../typedHooks/queries/queryClientFormOptions";
import useCreateClient from "../../typedHooks/mutations/useCreateClient";
import useUpdateClient from "../../typedHooks/mutations/useUpdateClient";
import { Broker, ClientGroup, Country } from "../../codegen/gql/graphql";
import { toast } from "react-toastify";

const CreateOrUpdateClientForm = ({ initialValues, closeAfterSubmit }: CreateOrUpdateClientFormProps) => {
  const clientExists = initialValues ? true : false;
  const { dataFromQuery: clientFormOptions } = useClientFormOptions({});

  const createClient = useCreateClient();
  const updateClient = useUpdateClient();

  const [companyName, setCompanyName] = useState<string | undefined>(initialValues?.companyName ?? undefined);
  const [brokerId, setBrokerId] = useState<string | undefined>(initialValues?.broker?.id ?? undefined);
  const [countryId, setCountryId] = useState<string | undefined>(initialValues?.country?.id ?? undefined);
  const [clientGroupId, setClientGroupId] = useState<string | undefined>(initialValues?.clientGroup?.id ?? undefined);

  const formFields: FormField[] = [
    {
      label: `Client info: ${companyName ?? "New client"}`,
      order: "order-first",
      type: "heading",
    },
    {
      initialValue: companyName,
      isRequired: true,
      label: "Client name",
      onChange: (description: string) => {
        setCompanyName(description ?? "");
      },
      order: "order-1",
      placeholder: "",
      showDefaultValue: false,
      type: "text",
    },
    {
      initialValue: clientGroupId,
      isRequired: false,
      label: "Client Group",
      onChange: (providedClientGroupId: string) => {
        setClientGroupId(providedClientGroupId && providedClientGroupId !== "0" ? providedClientGroupId : undefined);
      },
      options: clientFormOptions?.clientgroups
        ? clientFormOptions?.clientgroups.map((clientGroup: ClientGroup) => ({ id: String(clientGroup.id), name: clientGroup.clientGroupName }))
        : [],
      order: "order-1",
      placeholder: "Select an option",
      type: "dropdown",
    },
    {
      initialValue: brokerId,
      isRequired: true,
      label: "Introduced by*",
      onChange: (providedBrokerId: string) => {
        setBrokerId(providedBrokerId && providedBrokerId !== "0" ? providedBrokerId : undefined);
      },
      options: clientFormOptions?.brokers ? clientFormOptions?.brokers.map((broker: Broker) => ({ id: String(broker.id), name: broker.brokerName })) : [],
      order: "order-2",
      placeholder: "Select an option",
      type: "dropdown",
    },
    {
      initialValue: countryId,
      isRequired: true,
      label: "Country*",
      onChange: (providedCountryId: string) => {
        setCountryId(providedCountryId && providedCountryId !== "0" ? providedCountryId : undefined);
      },
      options: clientFormOptions?.countries
        ? clientFormOptions?.countries
            .filter((country: Country) => country.name !== undefined)
            .map((country: Country) => ({ id: String(country.id), name: country.name! }))
        : [],
      order: "order-2",
      placeholder: "Select an option",
      type: "dropdown",
    },
    {
      label: "Submit client info",
      order: "order-3",
      type: "submit",
    },
  ];

  const mutationKeys = {
    input: {
      companyName: companyName,
      brokerId: Number(brokerId),
      clientGroupId: clientGroupId ? Number(clientGroupId) : undefined,
      countryId: Number(countryId),
      ...(clientExists && { id: Number(initialValues!.id) }),
    },
  };

  const onSubmitFromParent = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (clientExists) {
        await updateClient({ variables: mutationKeys });
        toast.success(`Info for ${companyName} has been updated`);
      } else {
        await createClient({ variables: mutationKeys });
        toast.success(`${companyName} has been created`);
      }
      if (closeAfterSubmit) {
        closeAfterSubmit();
      }
    } catch (error) {
      toast.error(`Something went wrong in submitting information for ${companyName}`);
    }
  };

  if (clientFormOptions) {
    return (
      <FormGenerator
        closeFormFunction={closeAfterSubmit}
        customFormStyles={{
          parent:
            "h-full  w-full pb-[80px] border-box flex flex-wrap gap-y-8 bg-white p-5 rounded-xl justify-start md:justify-between lg:bp-[40px] lg:justify-between lg:p-[40px] lg:pb-[40px]",
          children: "w-[100%] md:w-[48.5%]",
        }}
        formFields={formFields}
        layoutOptions={{ hasLabelsOnTop: true, hasCloseButton: true }}
        onSubmitFromParent={onSubmitFromParent}
      />
    );
  }
  return (
    <div className="w-fit h-[120px] bg-white rounded-lg flex flex-wrap justify-center items-center gap-4 p-5">
      <span className="text-center">Loading client data...</span>
      <CircularProgress />
    </div>
  );
};

export default CreateOrUpdateClientForm;

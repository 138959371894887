import { gql, useMutation } from "@apollo/client";

const MARK_DEALCONDITION_AS_CONFIRMED = gql`
  mutation MarkDealConditionAsConfirmed($input: MarkDealConditionAsConfirmedInput) {
    markdealconditionasconfirmed(input: $input) {
      ... on DealCondition {
        id
        isConfirmed
        updatedAt
        updatedBy {
          fullName
        }
      }
      ... on Error {
        errorCode
        message
      }
    }
  }
`;

const useMarkDealConditionAsConfirmed = () => {
  const [markDealConditionAsConfirmed] = useMutation(MARK_DEALCONDITION_AS_CONFIRMED, {
    refetchQueries: ["dealconditionscount"],
  });

  return markDealConditionAsConfirmed;
};

export default useMarkDealConditionAsConfirmed;

import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import AddDealModal from "../Modals/AddDealModal";
import { motion } from "framer-motion";
import helpers from "../../services/helpers";
import { AdminToggleProvider } from "../../contexts/AdminToggleContext";

const OpenDealFormButton = ({ deal }) => {
  const [showAddDealModal, setShowAddDealModal] = useState(false);
  const getInitialValues = helpers.getInitialValuesDeal;

  const slashMotion = {
    rest: { scale: 1, ease: "easeOut", duration: 0.2, type: "tween" },
    hover: {
      scale: 1,
      rotate: 20,
      transition: {
        duration: 0.2,
        type: "tween",
        ease: "easeIn",
      },
    },
  };
  const handleCloseForm = () => {
    setShowAddDealModal(false);
  };

  return (
    <>
      <div className="flex align-center min-w-[180px]">
        {deal && (
          <AdminToggleProvider>
            <AddDealModal
              open={showAddDealModal}
              handleClose={() => {
                handleCloseForm();
              }}
              initialValues={getInitialValues(deal)}
              closeAfterSubmit={() => {
                handleCloseForm();
              }}
            />
            <motion.button
              initial="rest"
              whileHover="hover"
              animate="rest"
              className={
                "rounded ml-4 text-primary-blue underline underline-offset-4 decoration-1 opacity-90 hover:text-gray-dark text-sm ease-in-out duration-300"
              }
              onClick={() => {
                setShowAddDealModal(true);
              }}
            >
              Update deal: {deal.olyxRef}
              <motion.button variants={slashMotion} className="transform">
                <EditIcon className="pb-2" />
              </motion.button>
            </motion.button>
          </AdminToggleProvider>
        )}
      </div>
    </>
  );
};
export default OpenDealFormButton;

import React, { createContext, useContext } from "react";

interface AdminToggleContext {
  adminTurnedOff: boolean;
  setAdminTurnedOff: (adminTurnedOff: boolean) => void;
}

const AdminToggleContext = createContext<AdminToggleContext | undefined>(undefined);

export const useAdminToggle = () => {
  const context = useContext(AdminToggleContext);
  if (!context) {
    throw new Error("useAdminToggle must be used within a AdminToggleProvider");
  }
  return context;
};

export const AdminToggleProvider = ({ children }: { children: React.ReactNode }) => {
  const [adminTurnedOff, setAdminTurnedOff] = React.useState(false);

  return (
    <AdminToggleContext.Provider
      value={{
        adminTurnedOff,
        setAdminTurnedOff,
      }}
    >
      {children}
    </AdminToggleContext.Provider>
  );
};

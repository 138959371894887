import { graphql } from "../../codegen/gql";
import { DealconditionsQuery, QueryDealConditionInput } from "../../codegen/gql/graphql";
import useGeneratedTypeQueryWithPagination from "../helpers/useGeneratedTypeQueryWithPagination";

const GET_DEALCONDITIONS = graphql(`
  query dealconditions($input: QueryDealConditionInput!) {
    dealconditions(input: $input) {
      ... on DealCondition {
        createdAt
        createdBy {
          id
          imageUrl
          fullName
        }
        deal {
          id
          olyxRef
        }
        description
        id
        isConfirmed
        isDeleted
        toBeResolvedAt
        updatedAt
        updatedBy {
          id
          imageUrl
          fullName
        }
      }
      ... on Error {
        errorCode
        message
      }
    }
  }
`);

const useDealConditions = (input: QueryDealConditionInput) =>
  useGeneratedTypeQueryWithPagination<DealconditionsQuery, QueryDealConditionInput>(GET_DEALCONDITIONS, input);

export default useDealConditions;

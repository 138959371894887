import React from "react";
import Moment from "react-moment";
import AvatarTooltip from "../AvatarTooltip/AvatarTooltip";
import IssueStatusButton from "../IssueStatusButton/IssueStatusButton";
import usePickUpDealNote from "../../hooks/usePickUpDealNote";
import format from "date-fns/format";
import { Link, useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import StyledMentionParagraph from "./StyledMentionParagraph";
import SingleDealNoteProps from "../../interfaces/SingleDealNoteProps";

function SingleDealNote({ dealNote, olyxRef, dealId }: SingleDealNoteProps) {
  const history = useHistory();

  const requiresAction = dealNote.status === "TODO";
  const isPickedUp = !requiresAction;
  const { pickUpDealNote } = usePickUpDealNote();

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <motion.div variants={item} className="mt-4">
      {dealNote && (
        <div>
          {isPickedUp && (
            <>
              <div className="flex items-center ml-1 align-center pt-1">
                <div className="w-7 h-7">
                  <AvatarTooltip
                    img={
                      dealNote.status === "INPROGRESS" && dealNote.inProgressBy && dealNote.inProgressBy.imageUrl
                        ? dealNote.inProgressBy.imageUrl
                        : dealNote.status === "DONE" && dealNote.doneBy && dealNote.doneBy.imageUrl
                        ? dealNote.doneBy.imageUrl
                        : "Anonymous"
                    }
                    broker={
                      dealNote.status === "INPROGRESS" && dealNote.inProgressBy && dealNote.inProgressBy.fullName
                        ? dealNote.inProgressBy.fullName
                        : dealNote.status === "DONE" && dealNote.doneBy && dealNote.doneBy.fullName
                        ? dealNote.doneBy.fullName
                        : "Anonymous"
                    }
                    hover={false}
                  />
                </div>
                <p className="ml-1 text-xs">
                  {` ${
                    dealNote.status === "INPROGRESS" && dealNote.inProgressBy && dealNote.inProgressBy.fullName
                      ? "In progress by " + dealNote.inProgressBy.fullName.split(/(\s+)/)[0]
                      : dealNote.status === "DONE" && dealNote.doneBy && dealNote.doneBy.fullName
                      ? "Done by " + dealNote.doneBy.fullName.split(/(\s+)/)[0]
                      : "Anonymous"
                  } on ${format(
                    new Date(
                      dealNote.status === "INPROGRESS" && dealNote.inProgressAt
                        ? dealNote.inProgressAt
                        : dealNote.status === "DONE" && dealNote.doneAt
                        ? dealNote.doneAt
                        : null
                    ),
                    "d MMM yyyy"
                  )}`}
                </p>
              </div>
              <div className="bg-gray-light w-0.5 h-2 ml-4"></div>
            </>
          )}
          <div className="comment-card mb-4" key={dealNote.id}>
            <IssueStatusButton status={dealNote.status} pickUpDealNote={pickUpDealNote} id={dealNote.id} />
            <div className="comment-card-content relative">
              {olyxRef && (
                <div className="text-primary-blue absolute right-0">
                  {" "}
                  <Link
                    className="cursor-pointer link-deal"
                    to={{
                      pathname: `/mydeals/${dealId}`,
                      state: { history: history.location },
                    }}
                  >
                    {olyxRef ?? "nan"}
                  </Link>
                </div>
              )}
              <div className="author">
                <div className="w-10 h-10">
                  <AvatarTooltip
                    img={dealNote.createdBy && dealNote.createdBy.imageUrl ? dealNote.createdBy.imageUrl : ""}
                    broker={dealNote.createdBy && dealNote.createdBy.fullName ? dealNote.createdBy.fullName : "Anonymous"}
                    hover={true}
                  />
                </div>
                <div className="p-1 px-2">
                  <p className="author-name">{dealNote.createdBy ? dealNote.createdBy.fullName : ""}</p>
                  <span>
                    <Moment fromNow>{dealNote.createdAt ?? null}</Moment>
                  </span>
                </div>
              </div>
              <StyledMentionParagraph textContent={dealNote.note} />
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
}

export default SingleDealNote;

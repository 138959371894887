import { useState } from "react";
import { useHistory } from "react-router-dom";
import useDeals from "../../hooks/useDeals";
import useDealOptions from "../../hooks/useDealOptions";
import FilterForm from "../CommissionAndInvoice/FilterForm";
import AddClientModal from "../../components/Modals/AddClientModal";
import AddProductModal from "../../components/Modals/AddProductModal";
import DealContainer from "./elements/DealContainer";
import NavigationButtonsDeals from "./elements/NavigationButtonsDeals";
import DealsTableHeader from "./elements/DealsTableHeader";

function Deals() {
  const [dealOptions] = useDealOptions();

  const [dateAfter, setDateAfter] = useState(null);
  const [dateBefore, setDateBefore] = useState(null);
  const [broker, setBroker] = useState("0");
  const [dealStatus, setDealStatus] = useState("4");
  const [olyxRef, setOlyxRef] = useState("");
  const history = useHistory();

  const queryDealsInput = {
    take: 20,
    brokerIds: broker === "0" || broker === null ? [] : [broker],
    olyxRefs: olyxRef !== "" && parseInt(olyxRef) > 100 ? [olyxRef] : undefined,
    after: dateAfter,
    before: dateBefore,
    dealStatusIds: dealStatus === "0" || dealStatus === null ? undefined : [dealStatus],
  };

  const { deals, navigateForward, navigateBack } = useDeals(queryDealsInput ?? undefined);

  const onChangeBrokerFunction = (event, value) => {
    setBroker(value);
  };

  const onChangeDealStatusFunction = (event, value) => {
    setDealStatus(value);
  };

  const formFields = {
    dateAfter: {
      label: "After",
      name: "after",
      defaultValue: "2021-01-01",
      value: dateAfter,
      onChange: (value) => setDateAfter(value),
    },
    dateBefore: {
      label: "Before",
      name: "before",
      defaultValue: "2021-02-01",
      value: dateBefore,
      onChange: (value) => setDateBefore(value),
    },
    broker: {
      label: "Broker",
      name: "broker",
      defaultValue: "0",
      value: broker,
      onChange: onChangeBrokerFunction,
      options:
        dealOptions && dealOptions.brokers && dealOptions.brokers.length > 0
          ? dealOptions.brokers
          : [
              { id: 0, brokerName: "name0" },
              { id: 1, brokerName: "name1" },
            ],
    },
    olyxRef: {
      label: "OlyxRef",
      name: "olyxRef",
      defaultValue: "",
      value: olyxRef,
      onChange: (event) => {
        setOlyxRef(event.target.value);
      },
    },
    dealStatus: {
      label: "Deal status",
      name: "dealStatus",
      defaultValue: "0",
      value: dealStatus,
      onChange: onChangeDealStatusFunction,
      options:
        dealOptions && dealOptions.dealstatuses
          ? dealOptions.dealstatuses
          : [
              { id: 0, description: "statusOne" },
              { id: 1, description: "statusTwo" },
            ],
    },
  };
  return (
    <>
      <div className="pt-1 my-0 mx-auto w-full bg-[#142a41] text-center"></div>
      <div className="px-[16px] py-[30px] mx-auto w-full bg-[#142a41]">
        <FilterForm formFields={formFields} />
        <div className="flex gap-2 justify-end">
          <AddClientModal />
          <AddProductModal />
        </div>
      </div>
      <div className=" m-auto mt-6 px-[16px] flex flex-wrap justify-between gap-2 gap-y-0">
        <DealsTableHeader />
        {deals &&
          deals.dealslist &&
          deals.dealslist.length > 0 &&
          deals.dealslist.map((deal) => {
            return <DealContainer deal={deal} history={history} key={deal.id} />;
          })}
      </div>
      <NavigationButtonsDeals navigateBack={navigateBack} navigateForward={navigateForward} />
    </>
  );
}
export default Deals;

import { toast } from "react-toastify";
import AddFormGeneric2 from "../AddFormGeneric/AddFormGeneric2";
import useCreateProduct from "../../typedHooks/mutations/useCreateProduct";
import useProductFormOptions from "../../typedHooks/queries/queryProductFormOptions";

const getInitialValues = (formFields) =>
  formFields.reduce((acc, formField) => {
    const fieldValuePair = {};
    fieldValuePair[formField.name] = formField["defaultValue"];
    return { ...acc, ...fieldValuePair };
  }, {});

const AddFormProduct = (props) => {
  const { dataFromQuery: productFormOptions } = useProductFormOptions();
  const createProduct = useCreateProduct();

  const formFields = [
    { name: "name", label: "Product Name", rank: 1, fieldType: "text", yupType: "string", required: true, defaultValue: "" },
    { name: "newLine", rank: 2.9, fieldType: "newLine" },
    {
      name: "categoryId",
      label: "Category",
      rank: 14,
      fieldType: "select",
      yupType: "integer",
      required: true,
      defaultValue: "",
      optionObjects: "categories",
      optionFieldsToDisplay: ["id", "name"],
    },
    !props.newInput ? { name: "id", label: "ID", rank: 1, fieldType: "immutable", yupType: "int", required: true, defaultValue: "0" } : undefined,
  ].filter((formField) => formField !== undefined);

  const initialValues = props.initialValues ? props.initialValues : getInitialValues(formFields);

  const mutationKeys = (values) => {
    return {
      name: values.name,
      categoryId: values.categoryId,
    };
  };

  const addFunction = (mutation, mutationKeys) => (values) => {
    mutation({ variables: mutationKeys(values) });
  };

  const afterSubmit = (values) => {
    toast.success(`New product submitted: ${values.name ? values.name : undefined}.`);
    if (props.closeAfterSubmit) {
      props.closeAfterSubmit();
    }
    if (props.refetch) {
      props.refetch();
    }
  };

  return (
    <>
      <AddFormGeneric2
        addFunction={addFunction(createProduct, mutationKeys)}
        afterSubmit={afterSubmit}
        className="addFormGeneric"
        formFields={formFields}
        formOptions={productFormOptions}
        initialValues={initialValues}
        newInput={true}
      />
    </>
  );
};

export default AddFormProduct;

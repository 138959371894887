import React from "react";
import { withRouter } from "react-router-dom";
import Deals from "../../components/Deals/Deals";

function DealPage() {
  return (
    <div styles={styles.contentWrapper}>
      <Deals />
    </div>
  );
}

const styles = {
  contentWrapper: {
    padding: 20,
  },
};

export default withRouter(DealPage);

import { AnimatePresence, motion, Variants } from "framer-motion";
import { useEffect, useState } from "react";
import { DealCondition, DealConfirmation } from "../../../codegen/gql/graphql";
import { useActivityHubFilter } from "../../../contexts/ActivityHubContexts";
import useDealConfirmations from "../../../typedHooks/queries/queryDealConfirmations";
import DealConfirmationContainer from "../../DealConfirmationContainer/DealConfirmationContainer";
import { addDays } from "date-fns";
import useDealConditions from "../../../typedHooks/queries/queryDealConditions";
import DealConditionContainer from "../../DealConditionContainer/DealConditionContainer";

const ChecklistItems = ({ column }: { column: string }) => {
  const { selectedUserViewInArray, checklistView } = useActivityHubFilter();
  const { dataFromQuery: dealConfirmationsFromQuery, refetch } = useDealConfirmations({
    userIds: selectedUserViewInArray,
    isConfirmed: column === "Done",
  });
  const [oneWeekFromNow, setOneWeekFromNow] = useState(addDays(new Date(), 7));
  const { dataFromQuery: dealConditionsFromQuery } = useDealConditions({
    isConfirmed: column === "Done",
    userIds: selectedUserViewInArray,
    toBeResolvedBefore: oneWeekFromNow,
  });

  useEffect(() => {
    refetch();
  }, [checklistView]);

  const dealConfirmations = dealConfirmationsFromQuery?.dealconfirmations as DealConfirmation[] | undefined;
  const dealConditions = dealConditionsFromQuery?.dealconditions as DealCondition[] | undefined;

  const combinedArray = [...(dealConditions ?? []), ...(dealConfirmations ?? [])];
  const sortedCombinedArray = [...combinedArray].sort((a, b) => {
    if (a.updatedAt && b.updatedAt) {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    }
    return 0;
  });

  const container: Variants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.05,
      },
    },
  };

  const item: Variants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <motion.div
      className="mt-3 flex flex-wrap gap-y-[16px]"
      variants={container}
      initial="hidden"
      animate={dealConfirmations && dealConfirmations.length > 0 ? "visible" : "hidden"}
      exit="hidden"
    >
      {sortedCombinedArray &&
        combinedArray &&
        (column === "Done" ? sortedCombinedArray : combinedArray).map((checklistItem, index) => {
          const isDealConfirmation = checklistItem.__typename === "DealConfirmation";
          const isDealCondition = checklistItem.__typename === "DealCondition";

          return (
            <AnimatePresence>
              <motion.div className="w-full" variants={item} key={checklistItem.id}>
                {isDealCondition ? (
                  <DealConditionContainer condition={checklistItem} olyxRef={checklistItem.deal.olyxRef.toString()} hideUpdatedByDisplay={column !== "Done"} />
                ) : isDealConfirmation ? (
                  <DealConfirmationContainer
                    key={checklistItem.id}
                    clientName={
                      checklistItem.tradeSide === "BUY"
                        ? checklistItem.deal.buyer?.companyName ?? "NO CLIENT SELECTED"
                        : checklistItem.deal.seller?.companyName ?? "NO CLIENT SELECTED"
                    }
                    confirmation={checklistItem}
                    olyxRef={String(checklistItem.deal.olyxRef)}
                  />
                ) : null}
              </motion.div>
            </AnimatePresence>
          );
        })}
    </motion.div>
  );
};

export default ChecklistItems;

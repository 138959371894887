import TextField from "@material-ui/core/TextField";
import AutoComplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";

function AutoCompleteInput(props) {
  const getOptionById = (id) => {
    return props.options.find((option) => option.id === id) || null;
  };

  const getOptionDescription = (optionObject) => {
    return optionObject && optionObject.__typename === "Broker"
      ? optionObject.brokerName
      : optionObject && optionObject.__typename === "DealStatus"
      ? optionObject.description
      : "";
  };

  const currentOptionDescription = getOptionDescription(getOptionById(props.value));

  return (
    <>
      <AutoComplete
        id={props.name}
        value={props.value}
        fullWidth={true}
        options={props.options.map((option) => option.id)}
        getOptionLabel={(option) => getOptionDescription(getOptionById(option))}
        onChange={props.onChange}
        renderInput={(params) => {
          const { inputProps, ...otherParams } = params;

          const modifiedInputProps = {
            ...inputProps,
            value: currentOptionDescription,
            initialValue: currentOptionDescription,
          };

          return (
            <>
              <TextField {...otherParams} inputProps={modifiedInputProps} label={props.label} variant="outlined" fullWidth margin="normal" />
            </>
          );
        }}
        includeInputInList={true}
        autoSelect={true}
        autoComplete={true}
      />
    </>
  );
}

export default AutoCompleteInput;

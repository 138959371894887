import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

function ChecklistItemForm({ itemId, itemAcceptMessage, markItemAsConfirmed }: { itemId: string; itemAcceptMessage: string; markItemAsConfirmed: any }) {
  const [userHasManuallyConfirmed, setUserHasManuallyConfirmed] = useState(false);

  return (
    <div className="w-full flex flex-wrap p-2 gap-2 bg-white rounded-bl-xl rounded-br-xl">
      <AnimatePresence>
        <motion.div className="w-full" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }}>
          <form className="relative w-full" onSubmit={(e) => e.preventDefault}>
            <label htmlFor={`confirmationCheckbox-${itemId}`} className="cursor-pointer flex text-[#7E8085] text-xs items-start gap-2 font-smallText">
              <input
                id={`confirmationCheckbox-${itemId}`}
                type="checkbox"
                className="cursor-pointer form-checkbox mt-[1px] opacity-0"
                onChange={() => setUserHasManuallyConfirmed(!userHasManuallyConfirmed)}
              />
              {itemAcceptMessage}
            </label>
            <div className="absolute pointer-events-none border-[1px] border-[#d5d5d5] top-[1px] w-[12px] h-[12px] rounded-[2px] flex items-center justify-center">
              {userHasManuallyConfirmed && <div className="bg-[#22b0ff] w-[8px] h-[8px] rounded-[2px]" />}
            </div>
            <input
              className="w-fit rounded-xl mt-2 p-2 py-0 text-sm block ml-auto text-white bg-primary-blue hover:bg-[#24abf6] disabled:bg-[#9ca3af] cursor-pointer"
              name="submit"
              type="submit"
              value="Submit"
              onClick={(e) => {
                e.preventDefault();
                markItemAsConfirmed();
              }}
              disabled={!userHasManuallyConfirmed}
            />
          </form>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

export default ChecklistItemForm;

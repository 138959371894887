import React from "react";
import Grid from "@material-ui/core/Grid";
// import DateFnsUtils from "@date-io/date-fns";
// import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
// import { Field, Form, Formik, FormikProps } from "formik";
import TextField from "@material-ui/core/TextField";

function TextInput(props) {
  return (
    <>
      <TextField
        disableToolbar
        variant="outlined"
        fullWidth
        margin="normal"
        id={props.name}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        varaint="outlined"
      />
    </>
  );
}

export default TextInput;

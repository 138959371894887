import CheckIcon from "@mui/icons-material/Check";
import { format } from "date-fns";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { DealConfirmation } from "../../codegen/gql/graphql";
import AvatarTooltip from "../AvatarTooltip/AvatarTooltip";
import ChecklistItemForm from "../ChecklistItemForm/ChecklistItemForm";
import useMarkDealConfirmationAsConfirmed from "../../typedHooks/mutations/useMarkDealConfirmationAsConfirmed";

function DealConfirmationContainer({ clientName, confirmation, olyxRef }: { clientName: string; confirmation: DealConfirmation; olyxRef?: string }) {
  const [formIsOpen, setFormIsOpen] = useState(false);
  const history = useHistory();
  const markDealConfirmationAsConfirmed = useMarkDealConfirmationAsConfirmed();

  const handleConfirm = () => {
    markDealConfirmationAsConfirmed({
      variables: {
        input: {
          dealConfirmationId: parseInt(confirmation.id, 10),
        },
      },
    });
  };

  return (
    <div className="w-full">
      {confirmation && confirmation.isConfirmed && (
        <div className="w-full flex flex-wrap h-[35px]">
          <div className="w-full flex flex-wrap gap-2 items-center">
            <div className="w-[27px] h-[27px] block rounded-full">
              <AvatarTooltip
                broker={confirmation.updatedBy.fullName ? confirmation.updatedBy.fullName : ""}
                img={confirmation.updatedBy.imageUrl ? confirmation.updatedBy.imageUrl : "default.png"}
                hover={true}
              />
            </div>
            <p className="text-xs no-leading">{confirmation.updatedAt ? format(new Date(confirmation.updatedAt), "d MMM yyyy") : ""}</p>
          </div>
          <div className="w-[2px] h-[8px] ml-[13px] bg-[#f4eced]"></div>
        </div>
      )}
      <div
        className={`w-full h-fit min-h-[36px] overflow-hidden rounded-xl border-[1px] border-[#f4eced] flex flex-wrap items-center gap-x-4 ${
          confirmation && confirmation.isConfirmed ? "" : " hover:bg-[#d8edf4]"
        }`}
      >
        <div
          onClick={() => confirmation && !confirmation.isConfirmed && setFormIsOpen(!formIsOpen)}
          className={`w-full flex p-[10px] ${confirmation.isConfirmed ? "cursor-default" : "cursor-pointer"}`}
        >
          <div className="flex gap-2 w-[calc(100%-40px)] text-[12px]">
            <button
              className={`self-center relative p-[3px] ${
                confirmation.isConfirmed ? "bg-[#22B0FF] p-[6px] cursor-default" : "bg-[#DC2334] m-[3px]"
              } rounded-full`}
            >
              {confirmation.isConfirmed && <CheckIcon className="absolute top-[1px] left-[1px]" style={{ fontSize: 10, color: "white" }} />}
            </button>
            <p className="font-smallText max-w-[calc(100%-20px)] self-center leading-none">
              {confirmation.isConfirmed ? "Confirmed by " + clientName : "Awaiting " + clientName + "'s response to deal confirmation"}
            </p>
          </div>
          {olyxRef && (
            <div className="text-primary-blue right-0 ml-auto">
              {" "}
              <Link
                className="cursor-pointer link-deal"
                to={{
                  pathname: `/mydeals/${confirmation.deal.id}`,
                  state: { history: history.location },
                }}
              >
                {olyxRef ?? "nan"}
              </Link>
            </div>
          )}
        </div>
        {formIsOpen && !confirmation.isConfirmed && (
          <ChecklistItemForm
            itemId={confirmation.id}
            itemAcceptMessage="I hereby confirm that the client has confirmed the deal in writing via email in response to the Deal Confirmation email I sent."
            markItemAsConfirmed={handleConfirm}
          />
        )}
      </div>
    </div>
  );
}

export default DealConfirmationContainer;

import React from "react";
import { withRouter } from "react-router-dom";
import ClientsTableExpanding from "../../components/Table/ClientsTableExpanding";
import CssBaseline from "@material-ui/core/CssBaseline";

function ClientPage(props) {
  // auth.currentUser.getIdToken(false).then((idToken) => {
  //   console.log("id token in clientpage");
  //   console.log(idToken);
  // });

  return (
    <>
      <CssBaseline />
      <div className="w-[calc(100%-40px)] m-4 lg:w-8/12 lg:mx-auto">
        <ClientsTableExpanding />
      </div>
      {/* <Grid xs={12} sm={12} md={6}> */}
      {/*   <div styles={styles.contentWrapper}> */}
      {/*     <AddFormClient /> */}
      {/*   </div> */}
      {/* </Grid> */}
    </>
  );
}

const styles = {
  contentWrapper: {
    padding: 20,
    alignContent: "center",
  },
  pageWrapper: {
    padding: 12,
  },
};

export default withRouter(ClientPage);

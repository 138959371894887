import React, { useState, useEffect } from "react";
import { useTable, useExpanded, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from "react-table";
import { motion, AnimatePresence } from "framer-motion";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import { matchSorter } from "match-sorter";

import styled from "styled-components";

// filter const
function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length;

  const StyledInput = styled.input`
    display: block;
    margin: 0px 0px;
    border 1px solid black;
    max-width: 7.5rem;
    padding: 0rem;
  `;

  return (
    <div style={styles.contentWrapper}>
      <StyledInput
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Define a default UI for filtering
function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: "1.1rem",
          border: "0",
        }}
      />
    </span>
  );
}
// end filter const

const TableExpandingBackendPagination = (props) => {
  const columns = props.columns;
  // const [data, setData] = useState([...props.data])
  // const [data, setData] = useState(props.filterData({'deals': [...props.data]}))
  const [data, setData] = useState(props.filterData([...props.data]));
  const [selectedRow, setSelectedRow] = useState(null);

  // const [originalData, setOriginalData] = useState([...props.data]);
  const originalData = props.data;

  useEffect(() => {
    setData(() => props.filterData([...props.data]));
  }, [props]);

  // filter consts

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  // end filter consts

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    state: { expanded, globalFilter, pageIndex, pageSize },
    preGlobalFilteredRows,
    toggleRowExpanded,
    toggleAllRowsExpanded,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      manualPagination: true,
      initialState: { pageIndex: 0, sortBy: props.sortBy ? props.sortBy : [] },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  // const firstPageRows = rows.slice(0, 10);

  return (
    <div className="mt-10">
      <table className="w-full" {...getTableProps()}>
        <thead className="pb-20">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <>
                  <td {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <div className="inline-block h-8">
                      <div className="flex">
                        <span className="pt-1 uppercase tracking-wider text-xs antialiased font-semibold text-gray-400 hover:text-gray-600">
                          {column.render("Header")}
                        </span>
                        <div className="arrow-div inline-block">
                          <div className="arrow w-5 h-5">
                            {column.isSorted ? column.isSortedDesc ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon /> : ""}{" "}
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  </td>
                </>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment>
                {/* <TableRow {...row.getRowProps()}> */}
                <tr className="text-left font-thin border-solid border-b last:border-b-0 border-gray-100 ">
                  {row.cells.map((cell) => {
                    return (
                      <td
                        onClick={() => {
                          toggleAllRowsExpanded(false);
                          toggleRowExpanded(row.id, !row.isExpanded);
                          setSelectedRow(row.id);
                        }}
                        className="h-14 pr-4 py-4"
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
                <AnimatePresence>
                  {row.id === selectedRow && row.isExpanded ? (
                    <tr>
                      {" "}
                      <td className="bg-gray-50 rounded-lg -top-1 relative h-fit" colSpan={visibleColumns.length}>
                        <motion.div
                          initial={{ height: 0 }}
                          animate={{
                            height: "auto",
                            transition: {
                              duration: 0.2,
                            },
                          }}
                          exit={{
                            height: 0,
                            transition: {
                              delay: 0.2,
                              duration: 0.2,
                            },
                          }}
                        >
                          {" "}
                          {props.renderRowSubComponent(originalData, row.original)}
                        </motion.div>
                      </td>
                    </tr>
                  ) : null}
                </AnimatePresence>

                {/* </TableRow> */}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const styles = {
  rowWrapper: {
    maxWidth: 50,
    paddingRight: 30,
  },
  contentWrapper: {
    maxWidth: "221px",
  },
};
export default TableExpandingBackendPagination;

import React, { useEffect, useRef, useState } from "react";
import { DateInputProps } from "../../../interfaces/InputProps";
import { format, isBefore, isToday } from "date-fns";

const DateInput = (props: DateInputProps) => {
  const [dateInputValue, setDateInputValue] = useState<string>("");
  const [inputHasBeenActive, setInputHasBeenActive] = useState<boolean>(false);
  let dateWithMonthName: string = "";

  let ref = useRef<HTMLInputElement>(null);

  const showDatePicker = () => {
    if (ref.current) {
      ref.current.focus();
      ref.current.showPicker();
    }
  };

  const inputIsValid = (valid: boolean) => {
    if (ref.current && !valid) {
      ref.current.setCustomValidity("Invalid field.");
    } else if (ref.current && valid) {
      ref.current.setCustomValidity("");
    }
  };

  if (props.initialValue !== undefined && props.initialValue !== "") {
    dateWithMonthName = format(new Date(props.initialValue), "d MMM yyyy");
  }

  useEffect(() => {
    setDateInputValue(dateWithMonthName);
  }, [dateWithMonthName]);

  useEffect(() => {
    if (ref.current && props.order === "order-first") {
      ref.current.focus();
    }
  }, [props.order]);

  return (
    <div
      id="rootDiv"
      className={`relative ${props.customFormStyles ? props.customFormStyles : "h-12 max-w-fit"} ${props.order ? props.order : ""} ${
        props.additionalStyles ? props.additionalStyles : ""
      }`}
    >
      <div
        className={"p-[4px] h-[2.7em] cursor-pointer flex flex-nowrap rounded-xl border border-solid border-gray-200 text-base items-center"}
        onClick={showDatePicker}
      >
        <div className="flex flex-row flex-nowrap items-center relative w-full h-full">
          <label
            className={`${
              props.hasLabelsOnTop ? "absolute " : "inherit "
            }bg-white left-1 bottom-[30px] whitespace-nowrap md:m-auto px-1 text-[#9aa6b2] max-md:text-sm md:text-base`}
            htmlFor={props.label}
          >
            {props.label}:
          </label>
          <span
            className={`absolute top-[6px] left-[10px] z-10 text-dark-gray-filters pointer-events-none
            ${props.showDefaultValue ? (inputHasBeenActive ? "text-opacity-100" : "text-opacity-50") : "text-opacity-100"}`}
          >
            {dateInputValue}
          </span>
          <div className="w-full h-full text-dark-gray-filters grow relative flex items-center flex-row flex-nowrap text-opacity-50">
            <input
              className={`w-full text-dark-gray-filters focus:outline-0 border-[1px] border-transparent
              focus:border-[1px] bg-white  invalid:border-red-200 invalid:bg-red-100  h-[100%] pl-2 pr-2 rounded-[6px]`}
              defaultValue={props.initialValue ? props.initialValue : ""}
              id={props.label}
              onChange={
                props.onChange
                  ? (e) => {
                      if (e.target.value) {
                        if (!props.newDateCanBeBeforeToday && isBefore(new Date(e.target.value), new Date()) && !isToday(new Date(e.target.value))) {
                          inputIsValid(false);
                          setDateInputValue("Please select a future date");
                        } else {
                          inputIsValid(true);
                          setDateInputValue(format(new Date(e.target.value), "d MMM yyyy"));
                          props.onChange(e.target.value);
                        }
                      } else {
                        setDateInputValue("");
                      }
                    }
                  : undefined
              }
              onFocus={() => {
                setInputHasBeenActive(true);
              }}
              ref={ref}
              required={props.isRequired ?? true}
              type={"date"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateInput;

import React from "react";
import { useParams } from "react-router-dom";
import DealDetails from "../../components/DealDetails";
import "./MyDealsDetailPage.scss";
import { AdminToggleProvider } from "../../contexts/AdminToggleContext";

function MyDealsDetailPage() {
  let { dealId } = useParams();

  return (
    <AdminToggleProvider>
      <DealDetails id={dealId} />
    </AdminToggleProvider>
  );
}

export default MyDealsDetailPage;

import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const CREATE_PRODUCT = gql`
  mutation Product($name: String!, $categoryId: ID) {
    product(name: $name, categoryId: $categoryId) {
      id
      name
      category {
        id
        name
      }
    }
  }
`;

const useCreateProduct = () => {
  const [useCreateProduct] = useMutation(CREATE_PRODUCT);

  return useCreateProduct;
};

export default useCreateProduct;

import Avatar from "@material-ui/core/Avatar";
import HoverPopover from "material-ui-popup-state/HoverPopover";
import { bindHover, bindPopover, usePopupState } from "material-ui-popup-state/hooks";
import Moment from "react-moment";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as OlyxCollaboration } from "../../assets/customicons/OlyxCollaboration.svg";
import helpers from "../../services/helpers";
import PaymentStatusIcon from "../PaymentStatusIcon/PaymentStatusIcon";
import "./DealCard.css";

function DealCard({ data, cardKey, hasAdminView }) {
  const history = useHistory();

  const popupStateBrokerSeller = usePopupState({
    variant: "popover",
    popupId: "brokerseller",
  });
  const popupStateBrokerBuyer = usePopupState({
    variant: "popover",
    popupId: "demoPopover",
  });

  // Getting the updated commisison to display on the card
  const commissionSeller =
    data.expectedRevenueAgainstInvoices && data.expectedRevenueAgainstInvoices.some((e) => e !== null && e.tradeSide === "SELL")
      ? helpers.getNewCommission(data.expectedRevenueAgainstInvoices.find((e) => e && e.tradeSide === "SELL").expectedRevenue)
      : 0;
  const commissionBuyer =
    data.expectedRevenueAgainstInvoices && data.expectedRevenueAgainstInvoices.some((e) => e !== null && e.tradeSide === "BUY")
      ? helpers.getNewCommission(data.expectedRevenueAgainstInvoices.find((e) => e && e.tradeSide === "BUY").expectedRevenue)
      : 0;

  const invoiceStatusSummarySell = data.expectedRevenueAgainstInvoices.find((el) => el && "tradeSide" in el && el.tradeSide === "SELL")
    ? data.expectedRevenueAgainstInvoices.find((el) => el && "tradeSide" in el && el.tradeSide === "SELL").invoiceStatusSummary
    : "";
  const invoiceStatusSummaryBuy = data.expectedRevenueAgainstInvoices.find((el) => el && "tradeSide" in el && el.tradeSide === "BUY")
    ? data.expectedRevenueAgainstInvoices.find((el) => el && "tradeSide" in el && el.tradeSide === "BUY").invoiceStatusSummary
    : "";

  return (
    <>
      {data && (
        <div
          key={cardKey}
          className={"grid grid-rows-2 dt-price-card deal-card d-flex flex-column dt-margin border border-gray-200 overflow-hidden drop-shadow-lg "}
        >
          <div className="p-4 relative">
            <div className="flex justify-between">
              {(data.hasOpenDealNote || data.hasOpenDealConfirmation || data.hasOpenDealCondition) && <div className="absolute flag-icon left-2 top-4"></div>}
              <Link
                style={{ fontFamily: "Gilroy Medium" }}
                className="text-2xl cursor-pointer link-deal text-primary-blue "
                to={{
                  pathname: `/mydeals/${data.id}`,
                  state: { history: history.location },
                }}
              >
                {data.olyxRef ?? "nan"}
              </Link>
              <span className="text-xs" style={{ fontFamily: "Gilroy Medium" }}>
                <Moment format="D MMM YYYY">{data.dealDate}</Moment>
              </span>
            </div>
            <div className="flex justify-center mb-2 absolute top-8 left-1/2 transform -translate-x-1/2">
              {helpers.getCategorySymbol(data.product && data.product.category && data.product.category.name ? data.product.category.name : "no product", {
                height: 20,
                width: 20,
                marginRight: 0,
                marginBottom: 0,
              })}
            </div>
            <span className="block text-2xl pt-4">{data.product ? data.product.name ?? "." : "."}</span>
            <span className="block">
              {data.productSpec && data.productSpec.description && data.productSpec.description !== "no description" ? data.productSpec.description : ""}
            </span>
            <span className="block pt-3">
              {helpers.formatMoney(data.pricePerUnit ?? "0", data.currencyDeal ? data.currencyDeal.description ?? "" : "")}
              {" - "}
              {data.volume ? Number(data.volume).toLocaleString("en-US") : ""}{" "}
              {data.unit && data.unit.id.toString() !== "2" && data.unit.id.toString() !== "6"
                ? data.unit.descriptionShort
                  ? data.unit.descriptionShort
                  : data.unit.description ?? ""
                : ""}
            </span>
          </div>
          <div className="grid content-center h-full grid-cols-3 grid-rows-3 p-4 text-white gap-y-8 place-items-center justify-items-center bg-deal-card-dark">
            <span className="text-xs">{data.seller && data.seller.companyName ? helpers.shortenCompanyName(data.seller.companyName) : ""}</span>
            <span className="text-xs">{" - "}</span>
            <span className="text-xs">{data.buyer && data.buyer.companyName ? helpers.shortenCompanyName(data.buyer.companyName) : ""}</span>
            <div>
              <div {...bindHover(popupStateBrokerSeller)}>
                <Avatar
                  src={data.brokerSeller && data.brokerSeller.userInfo && data.brokerSeller.userInfo.imageUrl ? data.brokerSeller.userInfo.imageUrl : null}
                  alt="No Image"
                  style={styles.brokerAvatar}
                ></Avatar>
              </div>
              <HoverPopover
                {...bindPopover(popupStateBrokerSeller)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div style={styles.popOver}>{data.brokerSeller ? data.brokerSeller.brokerName ?? "" : ""}</div>
              </HoverPopover>
            </div>
            <div className="w-12">
              <OlyxCollaboration />
            </div>
            <div>
              <div {...bindHover(popupStateBrokerBuyer)}>
                <Avatar
                  src={data.brokerBuyer && data.brokerBuyer.userInfo && data.brokerBuyer.userInfo.imageUrl ? data.brokerBuyer.userInfo.imageUrl : null}
                  alt="No Image"
                  style={styles.brokerAvatar}
                ></Avatar>
              </div>
              <HoverPopover
                {...bindPopover(popupStateBrokerBuyer)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div style={styles.popOver}>{data.brokerBuyer ? data.brokerBuyer.brokerName ?? "" : ""}</div>
              </HoverPopover>
            </div>
            <span>
              <div className="flex justify-center text-xs">
                <div className={`order-1 block ${invoiceStatusSummarySell === "CANCELLED" ? "line-through" : ""}`}>
                  {helpers.formatMoney(commissionSeller, data.currencyCommissionSeller ? data.currencyCommissionSeller.description ?? "" : "")}
                </div>{" "}
                <div className="block">
                  <PaymentStatusIcon size="small" invoiceStatusSummary={invoiceStatusSummarySell}></PaymentStatusIcon>
                </div>
              </div>
            </span>
            <span></span>
            <span>
              <div className="flex justify-center text-xs">
                <div className={`order-1 block ${invoiceStatusSummaryBuy === "CANCELLED" ? "line-through" : ""}`}>
                  {helpers.formatMoney(commissionBuyer, data.currencyCommissionBuyer ? data.currencyCommissionBuyer.description ?? "" : "")}
                </div>
                <div className="block">
                  <PaymentStatusIcon size="small" invoiceStatusSummary={invoiceStatusSummaryBuy}></PaymentStatusIcon>
                </div>
              </div>
            </span>
          </div>
        </div>
      )}
    </>
  );
}

const styles = {
  brokerAvatar: {
    width: "50px",
    height: "50px",
    borderRadius: "35px",
    padding: "0px",
  },
  popOver: {
    color: "#fff",
    margin: 10,
  },
};

export default DealCard;

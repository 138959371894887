import { graphql } from "../../codegen/gql";
import { DealconditionscountQuery, QueryDealConditionInput } from "../../codegen/gql/graphql";
import useGeneratedTypeQuery from "../helpers/useGeneratedTypeQuery";

export const GET_DEALCONDITIONSCOUNT = graphql(`
  query dealconditionscount($input: QueryDealConditionInput!) {
    dealconditionscount(input: $input)
  }
`);

const useDealConditionsCount = (input: QueryDealConditionInput) =>
  useGeneratedTypeQuery<DealconditionscountQuery, QueryDealConditionInput>(GET_DEALCONDITIONSCOUNT, input);

export default useDealConditionsCount;

import useGeneratedTypeQuery from "../helpers/useGeneratedTypeQuery";
import { graphql } from "../../codegen/gql";
import { ClientFormOptionsQuery, QueryDealNotesOverviewInput } from "../../codegen/gql/graphql";

const GET_CLIENTFORMOPTIONS = graphql(`
  query clientFormOptions {
    clients {
      id
      companyName
    }
    clientgroups {
      id
      clientGroupName
    }
    brokers {
      id
      brokerName
    }
    countries {
      id
      name
      code
    }
  }
`);

const useClientFormOptions = (input: {}) => useGeneratedTypeQuery<ClientFormOptionsQuery, {}>(GET_CLIENTFORMOPTIONS, input);

export default useClientFormOptions;

import FormModal from "./FormModal.js";
import AddFormProduct from "../AddFormProduct/AddFormProduct";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";

function AddProductModal(props) {
  return (
    <FormModal buttonText="Create new Product" ButtonIcon={LocalGasStationIcon}>
      <AddFormProduct newInput={true} refetch={props.refetch} />
    </FormModal>
  );
}

export default AddProductModal;

import { gql } from "@apollo/client";

const GET_DEALS_LIST = gql`
  query DealsList($input: QueryDealsInput!) {
    dealslist(input: $input) {
      id
      olyxRef
      buyer {
        id
        companyName
      }
      seller {
        id
        companyName
      }
      product {
        id
        name
        category {
          name
        }
      }
      volume
      unit {
        id
        description
        descriptionShort
      }
      pricePerUnit
      currencyDeal {
        id
        description
      }
      dealDate
      exportCountry {
        id
        name
      }
      importCountry {
        id
        name
      }
      brokerSeller {
        id
        brokerName
        userInfo {
          id
          imageUrl
        }
      }
      brokerBuyer {
        id
        brokerName
        userInfo {
          id
          imageUrl
        }
      }
      commissionSeller
      commissionBuyer
      currencyCommissionSeller {
        id
        description
      }
      currencyCommissionBuyer {
        id
        description
      }
      description
      productSpec {
        description
        product {
          id
          name
          category {
            name
          }
        }
      }
      expectedRevenue {
        id
        client {
          id
          companyName
        }
        dateExpected
      }
      expectedRevenueAgainstInvoices {
        tradeSide
        judgement
        allInvoicesAreClosed
        invoiceStatusSummary
        clientOnDeal {
          id
          companyName
        }
        expectedRevenue {
          id
          totalAmount
          currency {
            id
            description
          }
          client {
            id
            companyName
          }
          totalAmountPlusChanges
        }
      }
      dealStatus {
        id
        description
      }
      dealNotes {
        id
        createdAt
        createdBy {
          id
          fullName
          imageUrl
        }
        note
        inProgressAt
        inProgressBy {
          id
          fullName
          imageUrl
        }
        doneAt
        doneBy {
          id
          fullName
          imageUrl
        }
        status
      }
      hasOpenDealNote
      hasOpenDealConfirmation
      hasOpenDealCondition
    }
  }
`;

export default GET_DEALS_LIST;

function DealsTableHeader() {
  return (
    <div className="w-full grid grid-cols-12 px-6">
      <p className="w-full col-start-6 col-end-10 xl:col-start-3 xl:col-end-5 text-gray-500 text-[12px]">Deal confirmation</p>
      <p className="w-full col-start-5 col-end-7 text-gray-500 text-[12px] hidden xl:flex">Seller</p>
      <p className="w-full col-start-7 col-end-11 text-gray-500 text-[12px] ml-[16px] hidden xl:flex">Buyer</p>
      <p className="w-full col-start-12 col-end-13 text-gray-500 text-[12px]">ref #</p>
    </div>
  );
}
export default DealsTableHeader;

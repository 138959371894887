import MissingDealNumbers from "../../components/MissingDealNumbers/";
import "./DealManagementPage.scss";

function DealManagementPage() {
  return (
    <div className="root">
      <MissingDealNumbers />
    </div>
  );
}

export default DealManagementPage;

import React, { useState, useEffect } from "react";
import "./AddFormGeneric2.css";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import TextInput from "../FormFields/TextInput";
import TextInputMoney from "../FormFields/TextInputMoney";
import TextInputCalculated from "../FormFields/TextInputCalculated";
import TextInputLimitedOptions from "../FormFields/TextInputLimitedOptions";
import Immutable from "../FormFields/Immutable";
import SelectInputConditionalOptions from "../FormFields/SelectInputConditionalOptions";
import AutoCompleteInput from "../FormFields/AutoCompleteInput";
import DateInput from "../FormFields/DateInput";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import InFormButton from "../FormFields/InFormButton";
import Typography from "@material-ui/core/Typography";
import BooleanInput from "../FormFields/BooleanInput";

const AddFormGeneric2 = (props) => {
  // props: { formFields, formOptions, addFunction, initialValues, afterSubmit?, mutatonKeys(to do: check }
  const [validationSchema, setValidationSchema] = useState({});
  const [formFields, setFormFields] = useState([...props.formFields]);
  const [formOptions, setFormOptions] = useState(props.formOptions ? props.formOptions : false);

  const addFunction = props.addFunction;

  //set validationSchema when formFields update
  useEffect(() => {
    setValidationSchema(() => {
      return getFirstValidationSchema(formFields);
    });
  }, [formFields]);

  // render new formFields when props.formFields changes
  useEffect(() => {
    setFormFields([...props.formFields]);
  }, [props.formFields]);

  // load formOptions
  useEffect(() => {
    setFormOptions(() => props.formOptions);
  }, [props.formOptions]);

  const getFirstValidationSchema = (formFields) => {
    const validationSchema = formFields.reduce((acc, formField) => {
      const validation = {};
      // const required = (formField.required ? re)
      if (formField.yupType === "string") {
        if (formField.required) {
          validation[formField["name"]] = Yup.string()
            .max(500, "max 500 char")
            .required("Required");
        } else if (!formField.required) {
          validation[formField["name"]] = Yup.string().max(500, "max 500 char");
        }
      }
      if (formField.yupType === "integer") {
        if (formField.required) {
          validation[formField["name"]] = Yup.number()
            .integer("Choose int")
            .min(1, "Please choose an option")
            .required("Required");
        } else if (!formField.required) {
          validation[formField["name"]] = Yup.number()
            .integer("Choose int")
            .min(1, "Please choose an option");
        }
      }
      if (formField.yupType === "float") {
        if (formField.required) {
          validation[formField["name"]] = Yup.number()
            .min(0, "Choose positive number")
            .required("Required");
        } else if (!formField.required) {
          validation[formField["name"]] = Yup.number().min(0, "Choose positive number");
        }
      }
      if (formField.yupType === "floatsigned") {
        if (formField.required) {
          validation[formField["name"]] = Yup.number().required("Required");
        } else if (!formField.required) {
          validation[formField["name"]] = Yup.number().min(0, "Choose positive number");
        }
      }

      if (formField.yupType === "date") {
        if (formField.required) {
          validation[formField["name"]] = Yup.date()
            .min("2010-01-01", "Must be in 2010 or later")
            .required("Required");
        } else if (!formField.required) {
          validation[formField["name"]] = Yup.date().min("2010-01-01", "Must be in 2010 or later");
        }
      }

      return { ...acc, ...validation };
    }, {});
    return validationSchema;
  };

  const ErrorToken = () => {
    const { errors } = useFormikContext();
    const errorsKeys = Object.keys(errors);
    if (errorsKeys.length === 0) {
      return null;
    } else {
      const errorMessage = errorsKeys.reduce((acc, key) => {
        return (
          <>
            {acc}
            <Typography variant="body2">
              <strong>{key.toString()}</strong>
              {" : " + errors[key].toString()}
            </Typography>
          </>
        );
      }, <Typography variant="h6">Form is not filled out complete and/or correct, please check the following fields:</Typography>);
      return (
        <div className="error" style={{ paddingTop: 10 }}>
          {errorMessage}
        </div>
      );
    }
  };

  const onSubmit = (values, actions) => {
    if (values) {
      // addFunction({ variables: { input: [values] } });
      addFunction(values);
      if (props.afterSubmit) {
        props.afterSubmit(values);
      } else {
        window.location.reload(false);
        alert("Input Submitted");
      }
      if (props.afterSubmitActions) {
        props.afterSubmitActions(values, actions);
      }
      // actions.resetForm(props.initialValues);
    }
  };

  const preventSubmitEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="form">
        {props.header && formOptions && <div style={{ marginBottom: 20 }}>Please fill out the details below. Compulsory fields are marked *.</div>}
        <Formik initialValues={props.initialValues} validationSchema={Yup.object(validationSchema)} enableReinitialize={true} onSubmit={onSubmit}>
          <Form>
            <Grid onKeyPress={preventSubmitEnter} container spacing={3} className="form-field">
              {formOptions &&
                formFields
                  .sort((formFieldA, formFieldB) => formFieldA.rank - formFieldB.rank)
                  .map((formfield) => {
                    if (formfield.fieldType === "text") {
                      return <TextInput label={formfield.label} name={formfield.name} type={formfield.fieldType} required={formfield.required} />;
                    } else if (formfield.fieldType === "textMoney") {
                      return <TextInputMoney label={formfield.label} name={formfield.name} type={formfield.fieldType} required={formfield.required} />;
                    } else if (formfield.fieldType === "textCalculated") {
                      return <TextInputCalculated label={formfield.label} name={formfield.name} type={formfield.fieldType} required={formfield.required} />;
                    } else if (formfield.fieldType === "textLimitedOptions") {
                      return (
                        <TextInputLimitedOptions
                          label={formfield.label}
                          name={formfield.name}
                          type={formfield.fieldType}
                          required={formfield.required}
                          optionObjects={{ ...formOptions[formfield.optionObjects] }}
                          showOnCondition={formfield.showOnCondition ?? null}
                          limitOptions={formfield.limitOptions ?? null}
                        />
                      );
                    } else if (formfield.fieldType === "date") {
                      return (
                        <DateInput
                          label={formfield.label}
                          name={formfield.name}
                          type={formfield.fieldType}
                          defaultValue={formfield.defaultValue}
                          required={formfield.required}
                        />
                      );
                    } else if (formfield.fieldType === "select") {
                      return (
                        <AutoCompleteInput
                          id={formfield.name}
                          label={formfield.label}
                          name={formfield.name}
                          optionObjects={[...formOptions[formfield.optionObjects]]}
                          optionFieldsToDisplay={[...formfield.optionFieldsToDisplay]}
                          required={formfield.required}
                          conditionOn={formfield.conditionOn ? formfield.conditionOn : undefined}
                          sortingAlgorithm={formfield.sortingAlgorithm ? formfield.sortingAlgorithm : undefined}
                        />
                      );
                    } else if (formfield.fieldType === "selectConditionalOptions") {
                      return (
                        <SelectInputConditionalOptions
                          label={formfield.label}
                          name={formfield.name}
                          optionObjects={[...formOptions[formfield.optionObjects]]}
                          optionFieldsToDisplay={formfield.optionFieldsToDisplay}
                          conditionOn={formfield.conditionOn}
                        />
                      );
                    } else if (formfield.fieldType === "immutable") {
                      return <Immutable label={formfield.label} name={formfield.name} type={formfield.fieldType} />;
                    } else if (formfield.fieldType === "titleSection") {
                      return (
                        <>
                          <Grid item sm={12} xs={12}>
                            <Typography variant="h5">{formfield.title}</Typography>
                            {/* <h2>{formfield.title}</h2> */}
                          </Grid>
                        </>
                      );
                    } else if (formfield.fieldType === "newLine") {
                      return (
                        <>
                          <Grid item sm={12} xs={12}></Grid>
                        </>
                      );
                    } else if (formfield.fieldType === "button") {
                      return <InFormButton onClick={() => formfield.onClick(formFields.length)}>{formfield.label}</InFormButton>;
                    } else if (formfield.fieldType === "invisible") {
                      return null;
                    } else if (formfield.fieldType === "boolean") {
                      return <BooleanInput id={formfield.name} label={formfield.label} name={formfield.name} required={formfield.required} />;
                    } else {
                      return <div>Error: unknow fieldType</div>;
                    }
                  })}
              {!formOptions && <h3>Loading...</h3>}
            </Grid>
            <Grid className="pt-6" item sm={12} xs={12}>
              {formOptions && (
                <Button type="submit" onClick={() => onSubmit()} variant="contained" color="primary" style={{ color: "#ffffff" }}>
                  {props.submitButtonText ? props.submitButtonText : "Submit"}
                </Button>
              )}
            </Grid>
            <ErrorToken />
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default withRouter(AddFormGeneric2);

import useGeneratedTypeQuery from "../helpers/useGeneratedTypeQuery";
import { graphql } from "../../codegen/gql";
import { ProductFormOptionsQuery } from "../../codegen/gql/graphql";

const GET_PRODUCTFORMOPTIONS = graphql(`
  query productFormOptions {
    categories {
      id
      name
    }
  }
`);

const useProductFormOptions = (input: {}) => useGeneratedTypeQuery<ProductFormOptionsQuery, {}>(GET_PRODUCTFORMOPTIONS, input);

export default useProductFormOptions;

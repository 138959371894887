import { useMutation } from "@apollo/client";
import { gql } from "@apollo/client";

const UPDATE_CLIENT = gql`
  mutation ClientUpdate($input: ClientUpdateInput!) {
    clientupdate(input: $input) {
      ... on Client {
        id
        __typename
        companyName
        firstDealDate
        idExact
        companyNameExact
        broker {
          id
          brokerName
        }
        country {
          id
        }
        isActive
        updatedAt
      }
      ... on Error {
        errorCode
        message
        __typename
      }
      __typename
    }
  }
`;

const useUpdateClient = () => {
  const [updateClient] = useMutation(UPDATE_CLIENT);

  return updateClient;
};

export default useUpdateClient;

/* eslint-disable */
import * as types from "./graphql";
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  "\n  mutation Client($input: ClientInput!) {\n    client(input: $input) {\n      ... on Client {\n        id\n        companyName\n      }\n      ... on Error {\n        errorCode\n        message\n      }\n      __typename\n    }\n  }\n":
    types.ClientDocument,
  "\n  mutation createorupdateinvoicesonexpectedrevenue($input: CreateOrUpdateInvoicesOnExpectedRevenueInput!) {\n    createorupdateinvoicesonexpectedrevenue(input: $input) {\n      ... on Error {\n        errorCode\n        message\n      }\n      ... on InvoicesOnExpectedRevenue {\n        invoiceAmount\n        invoice {\n          id\n          invoiceNumber\n          description\n          totalAmount\n          totalAmountEur\n          dateInvoice\n          dateDue\n          dateEntry\n          dateOrder\n          orderNumber\n          allocationStatus\n          allocatedToParentExpectedRevenue\n          allocatedToAllExpectedRevenue\n          idExact\n          vatAmountDc\n          vatAmountFc\n          createdAt\n          updatedAt\n          isDeleted\n          invoiceStatusSummary\n          expectedRevenue {\n            id\n            totalAmount\n            dateExpected\n            allocatedFromParentInvoice\n            paidEur\n            totalAmountPlusChanges\n            invoiceSumEurAllocatedToExpectedRevenue\n            invoiceStatusSummary\n          }\n        }\n        assignedAt\n        expectedRevenue {\n          id\n          totalAmount\n        }\n      }\n    }\n  }\n":
    types.CreateorupdateinvoicesonexpectedrevenueDocument,
  "\n  mutation Product($name: String!, $categoryId: ID) {\n    product(name: $name, categoryId: $categoryId) {\n      id\n      name\n      category {\n        id\n        name\n      }\n    }\n  }\n":
    types.ProductDocument,
  "\n  mutation MarkDealConditionAsConfirmed($input: MarkDealConditionAsConfirmedInput) {\n    markdealconditionasconfirmed(input: $input) {\n      ... on DealCondition {\n        id\n        isConfirmed\n        updatedAt\n        updatedBy {\n          fullName\n        }\n      }\n      ... on Error {\n        errorCode\n        message\n      }\n    }\n  }\n":
    types.MarkDealConditionAsConfirmedDocument,
  "\n  mutation MarkDealConfirmationAsConfirmed($input: MarkDealConfirmationAsConfirmedInput) {\n    markdealconfirmationasconfirmed(input: $input) {\n      ... on DealConfirmation {\n        id\n        isConfirmed\n        updatedAt\n        updatedBy {\n          fullName\n        }\n      }\n      ... on Error {\n        errorCode\n        message\n      }\n    }\n  }\n":
    types.MarkDealConfirmationAsConfirmedDocument,
  "\n  mutation ClientUpdate($input: ClientUpdateInput!) {\n    clientupdate(input: $input) {\n      ... on Client {\n        id\n        __typename\n        companyName\n        firstDealDate\n        idExact\n        companyNameExact\n        broker {\n          id\n          brokerName\n        }\n        country {\n          id\n        }\n        isActive\n        updatedAt\n      }\n      ... on Error {\n        errorCode\n        message\n        __typename\n      }\n      __typename\n    }\n  }\n":
    types.ClientUpdateDocument,
  "\n  mutation ExpectedRevenue($input: ExpectedRevenueInput!) {\n    createexpectedrevenue(input: $input) {\n      ... on ExpectedRevenue {\n        id\n        deal {\n          id\n        }\n        client {\n          id\n        }\n        totalAmount\n        volume\n        currency {\n          id\n        }\n        dateExpected\n      }\n      ... on ExpectedRevenueError {\n        errorCode\n        message\n      }\n    }\n  }\n":
    types.ExpectedRevenueDocument,
  "\n  query clientFormOptions {\n    clients {\n      id\n      companyName\n    }\n    clientgroups {\n      id\n      clientGroupName\n    }\n    brokers {\n      id\n      brokerName\n    }\n    countries {\n      id\n      name\n      code\n    }\n  }\n":
    types.ClientFormOptionsDocument,
  "\n  query dealconditions($input: QueryDealConditionInput!) {\n    dealconditions(input: $input) {\n      ... on DealCondition {\n        createdAt\n        createdBy {\n          id\n          imageUrl\n          fullName\n        }\n        deal {\n          id\n          olyxRef\n        }\n        description\n        id\n        isConfirmed\n        isDeleted\n        toBeResolvedAt\n        updatedAt\n        updatedBy {\n          id\n          imageUrl\n          fullName\n        }\n      }\n      ... on Error {\n        errorCode\n        message\n      }\n    }\n  }\n":
    types.DealconditionsDocument,
  "\n  query dealconditionscount($input: QueryDealConditionInput!) {\n    dealconditionscount(input: $input)\n  }\n": types.DealconditionscountDocument,
  "\n  query dealconfirmations($input: QueryDealConfirmationInput!) {\n    dealconfirmations(input: $input) {\n      ... on DealConfirmation {\n        id\n        createdAt\n        isConfirmed\n        deal {\n          id\n          olyxRef\n          seller {\n            id\n            companyName\n          }\n          buyer {\n            id\n            companyName\n          }\n        }\n        tradeSide\n        updatedAt\n        updatedBy {\n          id\n          imageUrl\n          fullName\n        }\n      }\n      ... on Error {\n        errorCode\n        message\n      }\n    }\n  }\n":
    types.DealconfirmationsDocument,
  "\n  query dealconfirmationscount($input: QueryDealConfirmationInput!) {\n    dealconfirmationscount(input: $input)\n  }\n":
    types.DealconfirmationscountDocument,
  "\n  query dealnotesoverview($input: QueryDealNotesOverviewInput!) {\n    dealnotesoverview(input: $input) {\n      dealNotesTodo {\n        id\n        createdAt\n        createdBy {\n          id\n          fullName\n          imageUrl\n        }\n        note\n        inProgressAt\n        inProgressBy {\n          id\n          fullName\n          imageUrl\n        }\n        doneAt\n        doneBy {\n          id\n          fullName\n          imageUrl\n        }\n        status\n        deal {\n          id\n          olyxRef\n        }\n        mentions {\n          userInfo {\n            id\n            userUid\n          }\n        }\n      }\n      dealNotesInProgress {\n        id\n        createdAt\n        createdBy {\n          id\n          fullName\n          imageUrl\n        }\n        note\n        inProgressAt\n        inProgressBy {\n          id\n          fullName\n          imageUrl\n        }\n        doneAt\n        doneBy {\n          id\n          fullName\n          imageUrl\n        }\n        status\n        deal {\n          id\n          olyxRef\n        }\n        mentions {\n          userInfo {\n            id\n            userUid\n          }\n        }\n      }\n      dealNotesDone {\n        id\n        createdAt\n        createdBy {\n          id\n          fullName\n          imageUrl\n        }\n        note\n        inProgressAt\n        inProgressBy {\n          id\n          fullName\n          imageUrl\n        }\n        doneAt\n        doneBy {\n          id\n          fullName\n          imageUrl\n        }\n        status\n        deal {\n          id\n          olyxRef\n        }\n        mentions {\n          userInfo {\n            id\n            userUid\n          }\n        }\n      }\n    }\n  }\n":
    types.DealnotesoverviewDocument,
  "\n  query dealstatistics($input: QueryDealsInput) {\n    dealstatistics(input: $input) {\n      after\n      before\n      brokers {\n        id\n        brokerName\n        userInfo {\n          imageUrl\n        }\n      }\n      clientStatistics {\n        client {\n          id\n          companyName\n          createdAt\n          broker {\n            id\n            brokerName\n          }\n        }\n        commissionInEur\n      }\n      commissionTotalEur\n      dealCount\n      distinctBrokerCount\n      distinctClientCount\n      distinctProductCount\n      productStatistics {\n        product {\n          id\n          category {\n            id\n            name\n          }\n          name\n        }\n        commissionInEur\n      }\n      totalVolumePerUnit {\n        product {\n          name\n          id\n        }\n        volumePerUnit {\n          unit {\n            descriptionShort\n            description\n          }\n          totalVolume\n        }\n      }\n    }\n  }\n":
    types.DealstatisticsDocument,
  "\n  query Invoices($input: QueryInvoiceInput!) {\n    invoices(input: $input) {\n      id\n      invoiceNumber\n      isDeleted\n      description\n      type\n      client {\n        id\n        companyName\n      }\n      totalAmount\n      totalAmountEur\n      currency {\n        id\n        description\n      }\n      dateInvoice\n      updatedAt\n      invoiceStatus {\n        id\n        name\n      }\n      orderNumber\n      allocationStatus\n      allocatedToAllExpectedRevenue\n      expectedRevenue {\n        id\n        totalAmount\n        currency {\n          id\n          description\n        }\n        dateExpected\n        allocatedFromParentInvoice\n        allocatedFromAllInvoices\n        deal {\n          id\n          olyxRef\n        }\n        expectedRevenueChanges {\n          id\n          amount\n          reason\n        }\n        invoices {\n          id\n          totalAmount\n          orderNumber\n          allocatedToParentExpectedRevenue\n          invoiceStatusSummary\n          invoiceNumber\n          dateOrder\n          isDeleted\n          currency {\n            id\n            description\n          }\n        }\n      }\n    }\n  }\n":
    types.InvoicesDocument,
  "\n  query networkgraphdata($input: QueryDealsInput) {\n    networkgraphdata(input: $input) {\n      nodes {\n        name\n        value\n        id\n        img\n        olyxRefs\n      }\n      links {\n        source\n        target\n      }\n    }\n  }\n":
    types.NetworkgraphdataDocument,
  "\n  query OpenDealNoteCount {\n    opendealnotecount\n  }\n": types.OpenDealNoteCountDocument,
  "\n  query productFormOptions {\n    categories {\n      id\n      name\n    }\n  }\n": types.ProductFormOptionsDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation Client($input: ClientInput!) {\n    client(input: $input) {\n      ... on Client {\n        id\n        companyName\n      }\n      ... on Error {\n        errorCode\n        message\n      }\n      __typename\n    }\n  }\n"
): typeof documents["\n  mutation Client($input: ClientInput!) {\n    client(input: $input) {\n      ... on Client {\n        id\n        companyName\n      }\n      ... on Error {\n        errorCode\n        message\n      }\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation createorupdateinvoicesonexpectedrevenue($input: CreateOrUpdateInvoicesOnExpectedRevenueInput!) {\n    createorupdateinvoicesonexpectedrevenue(input: $input) {\n      ... on Error {\n        errorCode\n        message\n      }\n      ... on InvoicesOnExpectedRevenue {\n        invoiceAmount\n        invoice {\n          id\n          invoiceNumber\n          description\n          totalAmount\n          totalAmountEur\n          dateInvoice\n          dateDue\n          dateEntry\n          dateOrder\n          orderNumber\n          allocationStatus\n          allocatedToParentExpectedRevenue\n          allocatedToAllExpectedRevenue\n          idExact\n          vatAmountDc\n          vatAmountFc\n          createdAt\n          updatedAt\n          isDeleted\n          invoiceStatusSummary\n          expectedRevenue {\n            id\n            totalAmount\n            dateExpected\n            allocatedFromParentInvoice\n            paidEur\n            totalAmountPlusChanges\n            invoiceSumEurAllocatedToExpectedRevenue\n            invoiceStatusSummary\n          }\n        }\n        assignedAt\n        expectedRevenue {\n          id\n          totalAmount\n        }\n      }\n    }\n  }\n"
): typeof documents["\n  mutation createorupdateinvoicesonexpectedrevenue($input: CreateOrUpdateInvoicesOnExpectedRevenueInput!) {\n    createorupdateinvoicesonexpectedrevenue(input: $input) {\n      ... on Error {\n        errorCode\n        message\n      }\n      ... on InvoicesOnExpectedRevenue {\n        invoiceAmount\n        invoice {\n          id\n          invoiceNumber\n          description\n          totalAmount\n          totalAmountEur\n          dateInvoice\n          dateDue\n          dateEntry\n          dateOrder\n          orderNumber\n          allocationStatus\n          allocatedToParentExpectedRevenue\n          allocatedToAllExpectedRevenue\n          idExact\n          vatAmountDc\n          vatAmountFc\n          createdAt\n          updatedAt\n          isDeleted\n          invoiceStatusSummary\n          expectedRevenue {\n            id\n            totalAmount\n            dateExpected\n            allocatedFromParentInvoice\n            paidEur\n            totalAmountPlusChanges\n            invoiceSumEurAllocatedToExpectedRevenue\n            invoiceStatusSummary\n          }\n        }\n        assignedAt\n        expectedRevenue {\n          id\n          totalAmount\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation Product($name: String!, $categoryId: ID) {\n    product(name: $name, categoryId: $categoryId) {\n      id\n      name\n      category {\n        id\n        name\n      }\n    }\n  }\n"
): typeof documents["\n  mutation Product($name: String!, $categoryId: ID) {\n    product(name: $name, categoryId: $categoryId) {\n      id\n      name\n      category {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation MarkDealConditionAsConfirmed($input: MarkDealConditionAsConfirmedInput) {\n    markdealconditionasconfirmed(input: $input) {\n      ... on DealCondition {\n        id\n        isConfirmed\n        updatedAt\n        updatedBy {\n          fullName\n        }\n      }\n      ... on Error {\n        errorCode\n        message\n      }\n    }\n  }\n"
): typeof documents["\n  mutation MarkDealConditionAsConfirmed($input: MarkDealConditionAsConfirmedInput) {\n    markdealconditionasconfirmed(input: $input) {\n      ... on DealCondition {\n        id\n        isConfirmed\n        updatedAt\n        updatedBy {\n          fullName\n        }\n      }\n      ... on Error {\n        errorCode\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation MarkDealConfirmationAsConfirmed($input: MarkDealConfirmationAsConfirmedInput) {\n    markdealconfirmationasconfirmed(input: $input) {\n      ... on DealConfirmation {\n        id\n        isConfirmed\n        updatedAt\n        updatedBy {\n          fullName\n        }\n      }\n      ... on Error {\n        errorCode\n        message\n      }\n    }\n  }\n"
): typeof documents["\n  mutation MarkDealConfirmationAsConfirmed($input: MarkDealConfirmationAsConfirmedInput) {\n    markdealconfirmationasconfirmed(input: $input) {\n      ... on DealConfirmation {\n        id\n        isConfirmed\n        updatedAt\n        updatedBy {\n          fullName\n        }\n      }\n      ... on Error {\n        errorCode\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation ClientUpdate($input: ClientUpdateInput!) {\n    clientupdate(input: $input) {\n      ... on Client {\n        id\n        __typename\n        companyName\n        firstDealDate\n        idExact\n        companyNameExact\n        broker {\n          id\n          brokerName\n        }\n        country {\n          id\n        }\n        isActive\n        updatedAt\n      }\n      ... on Error {\n        errorCode\n        message\n        __typename\n      }\n      __typename\n    }\n  }\n"
): typeof documents["\n  mutation ClientUpdate($input: ClientUpdateInput!) {\n    clientupdate(input: $input) {\n      ... on Client {\n        id\n        __typename\n        companyName\n        firstDealDate\n        idExact\n        companyNameExact\n        broker {\n          id\n          brokerName\n        }\n        country {\n          id\n        }\n        isActive\n        updatedAt\n      }\n      ... on Error {\n        errorCode\n        message\n        __typename\n      }\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation ExpectedRevenue($input: ExpectedRevenueInput!) {\n    createexpectedrevenue(input: $input) {\n      ... on ExpectedRevenue {\n        id\n        deal {\n          id\n        }\n        client {\n          id\n        }\n        totalAmount\n        volume\n        currency {\n          id\n        }\n        dateExpected\n      }\n      ... on ExpectedRevenueError {\n        errorCode\n        message\n      }\n    }\n  }\n"
): typeof documents["\n  mutation ExpectedRevenue($input: ExpectedRevenueInput!) {\n    createexpectedrevenue(input: $input) {\n      ... on ExpectedRevenue {\n        id\n        deal {\n          id\n        }\n        client {\n          id\n        }\n        totalAmount\n        volume\n        currency {\n          id\n        }\n        dateExpected\n      }\n      ... on ExpectedRevenueError {\n        errorCode\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query clientFormOptions {\n    clients {\n      id\n      companyName\n    }\n    clientgroups {\n      id\n      clientGroupName\n    }\n    brokers {\n      id\n      brokerName\n    }\n    countries {\n      id\n      name\n      code\n    }\n  }\n"
): typeof documents["\n  query clientFormOptions {\n    clients {\n      id\n      companyName\n    }\n    clientgroups {\n      id\n      clientGroupName\n    }\n    brokers {\n      id\n      brokerName\n    }\n    countries {\n      id\n      name\n      code\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query dealconditions($input: QueryDealConditionInput!) {\n    dealconditions(input: $input) {\n      ... on DealCondition {\n        createdAt\n        createdBy {\n          id\n          imageUrl\n          fullName\n        }\n        deal {\n          id\n          olyxRef\n        }\n        description\n        id\n        isConfirmed\n        isDeleted\n        toBeResolvedAt\n        updatedAt\n        updatedBy {\n          id\n          imageUrl\n          fullName\n        }\n      }\n      ... on Error {\n        errorCode\n        message\n      }\n    }\n  }\n"
): typeof documents["\n  query dealconditions($input: QueryDealConditionInput!) {\n    dealconditions(input: $input) {\n      ... on DealCondition {\n        createdAt\n        createdBy {\n          id\n          imageUrl\n          fullName\n        }\n        deal {\n          id\n          olyxRef\n        }\n        description\n        id\n        isConfirmed\n        isDeleted\n        toBeResolvedAt\n        updatedAt\n        updatedBy {\n          id\n          imageUrl\n          fullName\n        }\n      }\n      ... on Error {\n        errorCode\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query dealconditionscount($input: QueryDealConditionInput!) {\n    dealconditionscount(input: $input)\n  }\n"
): typeof documents["\n  query dealconditionscount($input: QueryDealConditionInput!) {\n    dealconditionscount(input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query dealconfirmations($input: QueryDealConfirmationInput!) {\n    dealconfirmations(input: $input) {\n      ... on DealConfirmation {\n        id\n        createdAt\n        isConfirmed\n        deal {\n          id\n          olyxRef\n          seller {\n            id\n            companyName\n          }\n          buyer {\n            id\n            companyName\n          }\n        }\n        tradeSide\n        updatedAt\n        updatedBy {\n          id\n          imageUrl\n          fullName\n        }\n      }\n      ... on Error {\n        errorCode\n        message\n      }\n    }\n  }\n"
): typeof documents["\n  query dealconfirmations($input: QueryDealConfirmationInput!) {\n    dealconfirmations(input: $input) {\n      ... on DealConfirmation {\n        id\n        createdAt\n        isConfirmed\n        deal {\n          id\n          olyxRef\n          seller {\n            id\n            companyName\n          }\n          buyer {\n            id\n            companyName\n          }\n        }\n        tradeSide\n        updatedAt\n        updatedBy {\n          id\n          imageUrl\n          fullName\n        }\n      }\n      ... on Error {\n        errorCode\n        message\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query dealconfirmationscount($input: QueryDealConfirmationInput!) {\n    dealconfirmationscount(input: $input)\n  }\n"
): typeof documents["\n  query dealconfirmationscount($input: QueryDealConfirmationInput!) {\n    dealconfirmationscount(input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query dealnotesoverview($input: QueryDealNotesOverviewInput!) {\n    dealnotesoverview(input: $input) {\n      dealNotesTodo {\n        id\n        createdAt\n        createdBy {\n          id\n          fullName\n          imageUrl\n        }\n        note\n        inProgressAt\n        inProgressBy {\n          id\n          fullName\n          imageUrl\n        }\n        doneAt\n        doneBy {\n          id\n          fullName\n          imageUrl\n        }\n        status\n        deal {\n          id\n          olyxRef\n        }\n        mentions {\n          userInfo {\n            id\n            userUid\n          }\n        }\n      }\n      dealNotesInProgress {\n        id\n        createdAt\n        createdBy {\n          id\n          fullName\n          imageUrl\n        }\n        note\n        inProgressAt\n        inProgressBy {\n          id\n          fullName\n          imageUrl\n        }\n        doneAt\n        doneBy {\n          id\n          fullName\n          imageUrl\n        }\n        status\n        deal {\n          id\n          olyxRef\n        }\n        mentions {\n          userInfo {\n            id\n            userUid\n          }\n        }\n      }\n      dealNotesDone {\n        id\n        createdAt\n        createdBy {\n          id\n          fullName\n          imageUrl\n        }\n        note\n        inProgressAt\n        inProgressBy {\n          id\n          fullName\n          imageUrl\n        }\n        doneAt\n        doneBy {\n          id\n          fullName\n          imageUrl\n        }\n        status\n        deal {\n          id\n          olyxRef\n        }\n        mentions {\n          userInfo {\n            id\n            userUid\n          }\n        }\n      }\n    }\n  }\n"
): typeof documents["\n  query dealnotesoverview($input: QueryDealNotesOverviewInput!) {\n    dealnotesoverview(input: $input) {\n      dealNotesTodo {\n        id\n        createdAt\n        createdBy {\n          id\n          fullName\n          imageUrl\n        }\n        note\n        inProgressAt\n        inProgressBy {\n          id\n          fullName\n          imageUrl\n        }\n        doneAt\n        doneBy {\n          id\n          fullName\n          imageUrl\n        }\n        status\n        deal {\n          id\n          olyxRef\n        }\n        mentions {\n          userInfo {\n            id\n            userUid\n          }\n        }\n      }\n      dealNotesInProgress {\n        id\n        createdAt\n        createdBy {\n          id\n          fullName\n          imageUrl\n        }\n        note\n        inProgressAt\n        inProgressBy {\n          id\n          fullName\n          imageUrl\n        }\n        doneAt\n        doneBy {\n          id\n          fullName\n          imageUrl\n        }\n        status\n        deal {\n          id\n          olyxRef\n        }\n        mentions {\n          userInfo {\n            id\n            userUid\n          }\n        }\n      }\n      dealNotesDone {\n        id\n        createdAt\n        createdBy {\n          id\n          fullName\n          imageUrl\n        }\n        note\n        inProgressAt\n        inProgressBy {\n          id\n          fullName\n          imageUrl\n        }\n        doneAt\n        doneBy {\n          id\n          fullName\n          imageUrl\n        }\n        status\n        deal {\n          id\n          olyxRef\n        }\n        mentions {\n          userInfo {\n            id\n            userUid\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query dealstatistics($input: QueryDealsInput) {\n    dealstatistics(input: $input) {\n      after\n      before\n      brokers {\n        id\n        brokerName\n        userInfo {\n          imageUrl\n        }\n      }\n      clientStatistics {\n        client {\n          id\n          companyName\n          createdAt\n          broker {\n            id\n            brokerName\n          }\n        }\n        commissionInEur\n      }\n      commissionTotalEur\n      dealCount\n      distinctBrokerCount\n      distinctClientCount\n      distinctProductCount\n      productStatistics {\n        product {\n          id\n          category {\n            id\n            name\n          }\n          name\n        }\n        commissionInEur\n      }\n      totalVolumePerUnit {\n        product {\n          name\n          id\n        }\n        volumePerUnit {\n          unit {\n            descriptionShort\n            description\n          }\n          totalVolume\n        }\n      }\n    }\n  }\n"
): typeof documents["\n  query dealstatistics($input: QueryDealsInput) {\n    dealstatistics(input: $input) {\n      after\n      before\n      brokers {\n        id\n        brokerName\n        userInfo {\n          imageUrl\n        }\n      }\n      clientStatistics {\n        client {\n          id\n          companyName\n          createdAt\n          broker {\n            id\n            brokerName\n          }\n        }\n        commissionInEur\n      }\n      commissionTotalEur\n      dealCount\n      distinctBrokerCount\n      distinctClientCount\n      distinctProductCount\n      productStatistics {\n        product {\n          id\n          category {\n            id\n            name\n          }\n          name\n        }\n        commissionInEur\n      }\n      totalVolumePerUnit {\n        product {\n          name\n          id\n        }\n        volumePerUnit {\n          unit {\n            descriptionShort\n            description\n          }\n          totalVolume\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query Invoices($input: QueryInvoiceInput!) {\n    invoices(input: $input) {\n      id\n      invoiceNumber\n      isDeleted\n      description\n      type\n      client {\n        id\n        companyName\n      }\n      totalAmount\n      totalAmountEur\n      currency {\n        id\n        description\n      }\n      dateInvoice\n      updatedAt\n      invoiceStatus {\n        id\n        name\n      }\n      orderNumber\n      allocationStatus\n      allocatedToAllExpectedRevenue\n      expectedRevenue {\n        id\n        totalAmount\n        currency {\n          id\n          description\n        }\n        dateExpected\n        allocatedFromParentInvoice\n        allocatedFromAllInvoices\n        deal {\n          id\n          olyxRef\n        }\n        expectedRevenueChanges {\n          id\n          amount\n          reason\n        }\n        invoices {\n          id\n          totalAmount\n          orderNumber\n          allocatedToParentExpectedRevenue\n          invoiceStatusSummary\n          invoiceNumber\n          dateOrder\n          isDeleted\n          currency {\n            id\n            description\n          }\n        }\n      }\n    }\n  }\n"
): typeof documents["\n  query Invoices($input: QueryInvoiceInput!) {\n    invoices(input: $input) {\n      id\n      invoiceNumber\n      isDeleted\n      description\n      type\n      client {\n        id\n        companyName\n      }\n      totalAmount\n      totalAmountEur\n      currency {\n        id\n        description\n      }\n      dateInvoice\n      updatedAt\n      invoiceStatus {\n        id\n        name\n      }\n      orderNumber\n      allocationStatus\n      allocatedToAllExpectedRevenue\n      expectedRevenue {\n        id\n        totalAmount\n        currency {\n          id\n          description\n        }\n        dateExpected\n        allocatedFromParentInvoice\n        allocatedFromAllInvoices\n        deal {\n          id\n          olyxRef\n        }\n        expectedRevenueChanges {\n          id\n          amount\n          reason\n        }\n        invoices {\n          id\n          totalAmount\n          orderNumber\n          allocatedToParentExpectedRevenue\n          invoiceStatusSummary\n          invoiceNumber\n          dateOrder\n          isDeleted\n          currency {\n            id\n            description\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query networkgraphdata($input: QueryDealsInput) {\n    networkgraphdata(input: $input) {\n      nodes {\n        name\n        value\n        id\n        img\n        olyxRefs\n      }\n      links {\n        source\n        target\n      }\n    }\n  }\n"
): typeof documents["\n  query networkgraphdata($input: QueryDealsInput) {\n    networkgraphdata(input: $input) {\n      nodes {\n        name\n        value\n        id\n        img\n        olyxRefs\n      }\n      links {\n        source\n        target\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query OpenDealNoteCount {\n    opendealnotecount\n  }\n"
): typeof documents["\n  query OpenDealNoteCount {\n    opendealnotecount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query productFormOptions {\n    categories {\n      id\n      name\n    }\n  }\n"
): typeof documents["\n  query productFormOptions {\n    categories {\n      id\n      name\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;

import CheckIcon from "@mui/icons-material/Check";
import { format } from "date-fns";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { DealCondition } from "../../codegen/gql/graphql";
import AvatarTooltip from "../AvatarTooltip/AvatarTooltip";
import ChecklistItemForm from "../ChecklistItemForm/ChecklistItemForm";
import useMarkDealConditionAsConfirmed from "../../typedHooks/mutations/useMarkDealConditionAsConfirmed";

function DealConditionContainer({ condition, olyxRef, hideUpdatedByDisplay }: { condition: DealCondition; olyxRef?: string; hideUpdatedByDisplay: boolean }) {
  const [formIsOpen, setFormIsOpen] = useState(false);
  const history = useHistory();
  const today = new Date();
  const markDealConditionAsConfirmed = useMarkDealConditionAsConfirmed();

  const handleConfirm = () => {
    markDealConditionAsConfirmed({
      variables: {
        input: {
          dealConditionId: parseInt(condition.id, 10),
        },
      },
    });
  };

  return (
    <div className="w-full">
      {!hideUpdatedByDisplay && (
        <div className="w-full flex flex-wrap h-[35px]">
          <div className="w-full flex flex-wrap gap-2 items-center">
            <div className="w-[27px] h-[27px] block rounded-full">
              <AvatarTooltip
                broker={condition.updatedBy.fullName ? condition.updatedBy.fullName : ""}
                img={condition.updatedBy.imageUrl ? condition.updatedBy.imageUrl : "default.png"}
                hover={true}
              />
            </div>
            <p className="text-xs no-leading">{condition.updatedAt ? format(new Date(condition.updatedAt), "d MMM yyyy") : ""}</p>
          </div>
          <div className="w-[2px] h-[8px] ml-[13px] bg-[#f4eced]"></div>
        </div>
      )}
      <div
        className={`w-full h-fit min-h-[36px] rounded-xl border-[1px] border-[#f4eced] flex flex-wrap items-center gap-x-4 ${
          condition && condition.isConfirmed ? "" : " hover:bg-[#d8edf4]"
        }`}
      >
        <div
          onClick={() => condition && !condition.isConfirmed && setFormIsOpen(!formIsOpen)}
          className={`relative w-full flex flex-wrap p-[10px] ${condition.isConfirmed ? "cursor-default" : "cursor-pointer"}`}
        >
          <div className={`flex gap-2 w-full text-[12px] ${olyxRef ? "w-[calc(100%-40px)]" : "w-full"}`}>
            <button
              className={`self-baseline relative p-[3px] ${
                condition.isConfirmed ? "bg-[#22B0FF] p-[6px] mt-[2px] cursor-default" : "bg-[#DC2334] m-[3px] mt-[5px]"
              } rounded-full`}
            >
              {condition.isConfirmed && <CheckIcon className="absolute top-[1px] left-[1px]" style={{ fontSize: 10, color: "white" }} />}
            </button>
            <div className="w-full">
              <p className="font-smallText text-[12px] text-[#A7A7A7] w-fit">
                <span className="font-smallText w-fit pr-1 text-black">Condition: </span>
                {condition.description}
              </p>
            </div>
          </div>
          {olyxRef && (
            <div className="text-primary-blue right-0 ml-auto">
              {" "}
              <Link
                className="cursor-pointer link-deal"
                to={{
                  pathname: `/mydeals/${condition.deal.id}`,
                  state: { history: history.location },
                }}
              >
                {olyxRef ?? "nan"}
              </Link>
            </div>
          )}
          <div
            className={` min-h-[16px] flex w-fit border-[1px] border-[#f4eced] rounded-md px-1 align-center mt-[4px] ml-[16px] ${
              new Date(condition.toBeResolvedAt) < today && !condition.isConfirmed ? "bg-red-500 border-red-500 text-white" : "bg-[#f4eced] text-black"
            } ${olyxRef ? "relative" : "relative"}`}
          >
            <p className="text-[10px] w-[30px] text-right pr-2 flex items-center justify-end leading-none">due:</p>
            <p className="text-[10px] w-[70px] text-right flex items-center justify-center leading-none">
              {condition.toBeResolvedAt ? format(new Date(condition.toBeResolvedAt), "d MMM yyyy") : ""}
            </p>
          </div>
        </div>
        {formIsOpen && !condition.isConfirmed && (
          <ChecklistItemForm
            itemId={condition.id}
            itemAcceptMessage=" I hereby confirm that the condition this deal is subject to has been resolved."
            markItemAsConfirmed={handleConfirm}
          />
        )}
      </div>
    </div>
  );
}

export default DealConditionContainer;

import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

function NavigationButtonsDeals({ navigateBack, navigateForward }) {
  return (
    <div className="flex justify-between m-auto w-4/6 p-4">
      <button
        onClick={() => {
          navigateBack();
          window.scrollTo({ top: 250, behavior: "smooth" });
        }}
      >
        <NavigateBeforeIcon className="bg-primary-blue p-1 rounded-md text-white" />
      </button>
      <button
        onClick={() => {
          navigateForward();
          window.scrollTo({ top: 250, behavior: "smooth" });
        }}
      >
        <NavigateNextIcon className="bg-primary-blue p-1 rounded-md text-white" />
      </button>
    </div>
  );
}
export default NavigationButtonsDeals;
